<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <!-- <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            Vuexy
          </h2>
        </b-link> -->

        <b-card-title class="mb-1">
          Verify Your Email
        </b-card-title>

        <!-- form -->
        <validation-observer
          ref="verifyEmailForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="verifyEmail"
          >

            <!-- email -->
            <b-form-group
              label-for="emailVerification"
              label="Please enter the code sent to your email to verify"
            >
              <validation-provider
                #default="{ errors }"
                name="OTP"
                rules="required"
              >
                <b-form-input
                  id="OTP"
                  v-model="emailVerificationCode"
                  name="email-verification-code"
                  :state="errors.length > 0 ? false:null"
                  placeholder="*****"
                  autofocus
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid || isLoading"
            >
              Submit
              <b-spinner
                v-if="isLoading"
                small
              />
            </b-button>
          </b-form>
        </validation-observer>
        <b-card-text class="text-center mt-2">
          <span>Did not receive code? </span>
          <b-link @click="resendEmailVerificationCode">
            <span>Click to Resend</span>
          </b-link>
          <b-spinner
            v-if="isResendingCode"
            small
            class="ml-1"
          />
        </b-card-text>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'
// import useJwt from '@/auth/jwt/useJwt'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      emailVerificationCode: '',
      status: '',
      // validation rules
      required,
      isLoading: false,
      isResendingCode: false,
    }
  },
  methods: {
    verifyEmail() {
      this.$refs.verifyEmailForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          this.$http.post('/membership/auth/email/verify', {
            email_code: this.emailVerificationCode,
          })
            .then(() => {
              this.$router.replace({ name: 'auth-verify-phone', params: { id: this.$router.currentRoute.params.id } })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Email Verified Successfully!',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                    },
                  })
                })

              // const { member, token, permissions } = response.data

              // useJwt.setToken(token)
              // localStorage.setItem('user', JSON.stringify(member))
              // localStorage.setItem('permissions', JSON.stringify(permissions))
              // const abilities = []
              // permissions.forEach(ability => {
              //   abilities.push({
              //     action: ability.split(' ')[1],
              //     subject: ability.split(' ')[0],
              //   })
              // })
              // this.$ability.update(abilities)
              // this.$toast({
              //   component: ToastificationContent,
              //   position: 'top-right',
              //   props: {
              //     title: 'Phone Verified Successfully!',
              //     icon: 'CoffeeIcon',
              //     variant: 'success',
              //   },
              // })
              // this.$router.replace({ name: 'metadata-upload' })
            })
            .catch(error => {
              this.$refs.verifyEmailForm.setErrors(error.response.data)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'The code is invalid.',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    resendEmailVerificationCode() {
      this.isResendingCode = true
      this.$http.get(`/membership/auth/${this.$router.currentRoute.params.id}/email/code/resend`)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Verification code resent!',
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error sending code. Please try again!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.isResendingCode = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
